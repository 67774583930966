<template>
	<div class="view-wrapper dashboard-page-wrapper">
		<div class="page-top-bg-addition" />
		<div class="mcontainer">
			<div class="section-row">
				<h1 class="title page-title">Dashboard</h1>
			</div>

			<div class="section-row pageSection">
				dashboard content
			</div>
		</div>
	</div>
</template>

<script>
import 'element-ui/lib/theme-chalk/date-picker.css';
import { mapState, mapActions } from 'vuex';
import {
	itemsFetchSetMixin /*, loadMoreMixin*/ /*, dateRangeMixin*/
} from '@/mixins';
// import { datePickerShortcuts } from '@/constants/global';
// import { prepareRangeParams, getItemValue } from '@/helpers';
// import { lastWeekDateRange } from '@/services/api/api_helpers';

export default {
	components: {
		// Counters: () => import('./Counters.vue'),
	},
	mixins: [itemsFetchSetMixin /*, dateRangeMixin*/ /*, loadMoreMixin*/],

	computed: {
		...mapState({
			// countersLoading: state => state.dashboard.countersLoading,
		}),

		navbarSettings: () => ({
			showFilter: true
			// showSaveButton: true,
			// showDateRange: true
		})
	},

	methods: {
		...mapActions({
			setup_navbar: 'setup_navbar'

			// fetch_counters: 'dashboard/fetch_counters',
		})

		/*fetchDashboardData(actions) {
			const params = { max: -1, plantId: this.globalFilters.plantId };
			this.initialResponses({ actions: actions, params: params });
		}*/
	},

	/*watch: {
		'globalFilters.plantId'() {
			this.fetchDashboardData(['fetch_counters']);
		}
	},*/

	created() {
		this.setup_navbar(this.navbarSettings);
		// this.fetchDashboardData(['fetch_counters']);
	},

	beforeDestroy() {
		// this.$socket.client.disconnect();
		this.setup_navbar({});
		// this.cleanLists(['set_counters']);
	}
};
</script>
